import { Link, Outlet } from "@remix-run/react";

import logoShort from "~/assets/logo_short.svg";
import { ImprintLink, PrivacyPolicyLink } from "~/components/navigation";

export default function AuthLayout() {
  return (
    <div className="flex min-h-full flex-col justify-between lg:justify-center lg:gap-3">
      <nav className="mx-auto w-full max-w-md p-8 pb-12 lg:pb-4 [view-transition-name:navbar]">
        <Link to="/" unstable_viewTransition className="flex items-start gap-4">
          <div className="pr-4 border-r-2 border-primary-900">
            <img src={logoShort} alt="BGHW" className="w-32 mb-2" width="350" height="80" />
          </div>
          <h1 className="text-2xl font-semibold pt-1">Leiter&#8209;Check</h1>
        </Link>
      </nav>
      <div className="mx-auto w-full max-w-lg px-8 [view-transition-name:main-container] [view-transition-] lg:rounded-lg lg:bg-white lg:py-8 lg:shadow-lg">
        <Outlet />
      </div>
      <footer className="mx-auto mt-4 w-full max-w-md px-8 pb-4 [view-transition-name:footer] lg:mt-6">
        <div className="flex justify-center gap-3">
          <PrivacyPolicyLink />
          <ImprintLink />
        </div>
      </footer>
    </div>
  );
}
